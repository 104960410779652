import { Component, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'app-signature',
  // templateUrl: './signature.component.html',
  template: `<canvas *ngIf="!picSign" #canvasEl width="200" height="100">
            </canvas><img *ngIf="picSign"  width="100" height="100" src="data:image/jpg;base64,{{sign}}">`,
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements AfterViewInit {
  /** Template reference to the canvas element */
  @ViewChild('canvasEl', { static: false }) canvasEl: ElementRef;    /** Canvas 2d context */
  private context: CanvasRenderingContext2D;
  picSign = false

  constructor() { }

  @Input() sign: any;

  ngAfterViewInit() {
    if (this.sign && this.sign.indexOf('/9j/') !== -1) {
      this.picSign = true
    }
    else {
      this.context = (this.canvasEl.nativeElement as HTMLCanvasElement).getContext('2d');
      this.draw();
    }
  }

  private draw() {
    var sign = this.sign || "";

    if (sign.indexOf(',/') !== -1) {
      var cord = sign.split(",");
      var maxx = Math.ceil(cord[0] / 2);
      var maxy = Math.ceil(cord[1] / 2);
      this.context.moveTo(maxx, maxy);

      for (var i = 0; i < cord.length; i += 2) {
        var p0 = Math.ceil(cord[i] / 2);
        var p1 = Math.ceil(cord[i + 1] / 2);
        var p2 = Math.ceil(cord[i + 2] / 2);
        var p3 = Math.ceil(cord[i + 3] / 2);
        if (cord[i] == "/") {

          this.context.moveTo(p2, p3);
          this.context.lineTo(p2, p3);
          this.context.stroke();
          maxx = Math.max(p2, maxx)
          maxy = Math.max(p3, maxy)
        } else {
          this.context.moveTo(p0, p1);
          this.context.lineTo(p2, p3);
          this.context.stroke();
          maxx = Math.max(p0, p2, maxx)
          maxy = Math.max(p1, p3, maxy)
        }
      }

    } else {
      var cord = sign.split("/");
      for (var i = 0; i < cord.length; i++) {
        var points = cord[i].split(",");
        var p0 = Math.ceil(points[0] / 2);
        var p1 = Math.ceil(points[1] / 2);
        var p2 = Math.ceil(points[2] / 2);
        var p3 = Math.ceil(points[3] / 2);
        this.context.moveTo(p0, p1);
        this.context.lineTo(p2, p3);
        this.context.stroke();
      }
    }

  }

}