import { ElementRef, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Row, Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { parseDate } from 'ngx-bootstrap';

const _today = new Date();

@Injectable({
  providedIn: 'root'
})
export class RRLib {

  static TODAY = _today.getFullYear() + "-" + RRLib.pad(_today.getMonth() + 1) + "-" + RRLib.pad(_today.getDate())
  static CYCLE = _today.getFullYear() + "-" + RRLib.pad(_today.getMonth() + 1)

  // static TODAY = '2020-12-27'
  // static CYCLE = '2020-01' 

  /***
 * @description add padding to a number
 * @param {type} number
 * @param {type} width_of_pad @default 2
 * @param {type} what_to_pad @default 0
 * @returns {String}
 */
  static pad(_number, width_of_pad = 2, what_to_pad = '0') {
    // what_to_pad = what_to_pad || '0';
    // width_of_pad = width_of_pad || 2;
    const number = _number + '';
    return number.length >= width_of_pad ? number : new Array(width_of_pad - number.length + 1).join(what_to_pad) + number;
  }


  static dateToLongString(date?: Date, format?: string): string {

    if (date == null) {
      date = new Date();
    }

    const ds = (new DatePipe('EN-US')).transform(date, format || `yyyy-MM-dd HH:mm:ss ZZZ`);
    return ds;
  }

  static dateToday(): string {
    return (new DatePipe('EN-US')).transform(new Date(), 'yyyy-MM-dd');
    // return '2018-04-27'
  }

  static cycleToday(): string {
    return (new DatePipe('EN-US')).transform(new Date(), 'yyyy-MM');
  }

  static downloadObjectAsFile(object: any, filename: string) {
    var sJson = JSON.stringify(object);
    var element = document.createElement('a');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  static nextCycle(): string {

    const stDate = new Date(`${RRLib.CYCLE}-01`)

    stDate.setDate(stDate.getDate() + 31)

    return (new DatePipe('EN-US')).transform(stDate, 'yyyy-MM');

  }

  constructor() {
  }

  static sort = (a, b, c?) => { return c ? (a[c] > b[c] ? 1 : a[c] < b[c] ? -1 : 0) : (a > b ? 1 : a < b ? -1 : 0) }
  static sortDesc = (a, b, c?) => { return c ? (a[c] > b[c] ? -1 : a[c] < b[c] ? 1 : 0) : (a > b ? -1 : a < b ? 1 : 0) }

  flatten(data: any, keys: string[]) {

    var result = {};

    function recurse(cur: any, level) {

      if (level < keys.length) {

        const k = keys[level].replace('{', '').replace('}', '');

        result[k] = cur.key;

        cur.forEach(function (snap): boolean {
          //console.log(snap.key);
          result[snap.key] = snap.val();
          if (snap.child(snap.key).hasChildren()) {
            recurse(snap, level + 1);
          }
          return false;
        })

      }
    }

    if (typeof data.val() == 'object') {

      recurse(data, 0);
    } else {
      const k = keys[0].replace('{', '').replace('}', '');

      if (keys.length > 1) {

        result[k] = data.key;
        const k2 = keys[1].replace('{', '').replace('}', '');
        result[k2] = data.val();

      } else {
        result[k] = data.val();
      }
    }

    return result;
  }

  static hashCode(str) { // java String#hashCode
    if (str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    } else {
      return 0;
    }
  }

  static hashCode8(str) { // java String#hashCode
    if (str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 7) - hash);
      }
      return hash;
    } else {
      return 0;
    }
  }

  static intToRGB(i) {
    var c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }

  static colorFromString(str: string): string {
    switch (str) {
      case "Reached": return 'gold'
      case "Unreached": return 'darkred'
      case "Called To Target": return 'green'

      default:
        return '#' + RRLib.intToRGB(RRLib.hashCode(str));
    }
  }

  static intToCode(i) {
    var c = (i & 0xFFFFFFFF)
      .toString(16)
      .toUpperCase();

    return "0000000".substring(0, 8 - c.length) + c;
  }

  static codeFromString(str: string): string {
    return RRLib.intToCode(RRLib.hashCode8(str));
  }

  static log(...args) {
    // console.log(args)
  }


  static downloadXLS(tables: any[], reportName: string, cycle: string, options?: DownloadXLSOptions) {

    const wb = new Workbook()

    tables.forEach(tableDef => {
      const table: any = (typeof tableDef === 'string') ? document.getElementById(tableDef) : document.getElementById(tableDef.tableID)

      const title = (typeof tableDef === 'string') ? reportName : tableDef.title || reportName

      const ws = wb.addWorksheet(title)

      if (options && options.alternateHeading) {
        const head = options.alternateHeading
        head.forEach(h => {
          const hRow = ws.addRow(h.row)
          hRow.font = h.font
        })
      } else {
        const titleRow = ws.addRow([title])
        titleRow.font = { name: 'Arial', family: 4, size: 16, bold: true };

        ws.addRow([`Cycle: ${cycle}`]).font = { bold: true }
        ws.addRow([`Downloaded: ${new Date()}`]).font = { size: 8 }
        ws.addRow([]);
      }

      if (table) {

        const l = table.rows.length
        for (let i = 0; i < l; i++) {
          const row = table.rows[i]
          const rl = row.cells.length

          const wRow = ws.addRow([])
          for (let ic = 0; ic < rl; ic++) {
            const cell = row.cells[ic]
            const style = window.getComputedStyle(cell)
            const cclass = cell.className
            const wCell = wRow.getCell(ic + 1)


            console.log('STYLE text align', style.textAlign)

            const cellValue = cell.textContent || cell.innerText || ""

            if (cellValue.indexOf('value|=') !== -1) {
              const dValue = cellValue.split('value|=')[1]
              const [vType, aValue, format] = dValue.split('||')
              switch (vType) {
                case 'N':
                  wCell.value = parseInt(aValue)
                  break;
                case 'F':
                  wCell.value = parseFloat(aValue)
                  break;
                case 'D':
                  wCell.value = new Date((aValue as string).trim())
                  break;
                default:
                  wCell.value = aValue
                  break;
              }

              if (format) {
                wCell.numFmt = format
              }

            } else {
              wCell.value = cellValue
            }

            const cw = cell.offsetWidth / 6


            if (cw > ws.columns[ic].width || (ws.columns[ic].width == undefined)) {
              if (options && options.colWidths) {
                ws.columns[ic].width = options.colWidths[ic]
              } else {
                ws.columns[ic].width = cw
              }
            }

            const canvas: HTMLCanvasElement = cell.getElementsByTagName('canvas')[0]
            const imgE: HTMLImageElement = cell.getElementsByTagName('img')[0]
            if (canvas || imgE) {
              const img = canvas ? canvas.toDataURL() : imgE.src
              const imgID = wb.addImage({ base64: img, extension: 'png' })

              ws.addImage(imgID, {

                tl: { col: parseInt(wCell.col) - 1, row: parseInt(wCell.row) - 1 },

                ext: { width: canvas ? canvas.width : imgE.width, height: canvas ? canvas.height : imgE.height },

              })

              wRow.height = canvas ? canvas.height : imgE.height

            }


            if (style && style.textAlign) {
              switch (style.textAlign) {
                case 'center':
                  wCell.alignment = { horizontal: 'center', wrapText: true }
                  break;
                case 'right':
                  wCell.alignment = { horizontal: 'right' }
                  break;
                default:
                  wCell.alignment = { horizontal: 'left', wrapText: true }
              }
            }

            // format headers and footers
            if (cclass && (cclass.indexOf('header') !== -1 || cclass.indexOf('footer') !== -1)) {
              wCell.font = { name: 'Arial', family: 4, size: 10, bold: true };
              wRow.height = 20
              if (cclass.indexOf('header') !== -1) {
                wCell.fill = { fgColor: { argb: 'FF6F42C1' }, bgColor: { argb: 'FF6F42C1' }, pattern: 'solid', type: 'pattern' }
                wCell.font.color = { argb: 'FFFFFFFF' }

              } else {
                wCell.border = {
                  top: { style: 'dotted', color: { argb: '11aaaaaa' } },
                  bottom: { style: 'double' },
                  left: { style: 'dotted', color: { argb: '00aaaaaa' } },
                  right: { style: 'dotted', color: { argb: '00aaaaaa' } }
                }
              }
            }
            if (cell.colSpan > 1) {
              const row = wRow.number
              const col = ic + 1
              ws.mergeCells(row, col, row, col + cell.colSpan - 1)
            }

            // RFB: style.backgroundColor is giving rgba(0,0,0,0)
            // todo: will do this later
            // else {
            //   if (style && style.backgroundColor) {
            //     const bgc = style.backgroundColor
            //     wCell.fill = { fgColor: {argb: `FF${bgc}`}, bgColor: { argb: `FF${bgc}` }, pattern: 'solid', type: 'pattern' }
            //   }
            // }

          }
        }
      }
    })

    wb.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, reportName + '.xlsx');
    })

  }

}

interface DownloadXLSOptions {
  alternateHeading?: any[],
  colWidths?: number[]
}