import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserGuard } from './user/user.guard';


const routes: Routes = [
  { path : '', redirectTo: 'dashboard', pathMatch:'full'},
  { path : 'dashboard', loadChildren: "./dashboard/dashboard.module#DashboardModule" , canActivate: [UserGuard]},
  { path : 'reports', loadChildren: "./reports/reports.module#ReportsModule" , canActivate: [UserGuard]},
  { path : 'user-management', loadChildren: "./user-management/user-management.module#UserManagementModule" , canActivate: [UserGuard]},
  { path : 'customers', loadChildren: './customers/customers.module#CustomersModule', canActivate: [UserGuard]},
  { path : 'approval', loadChildren: './approval/approval.module#ApprovalModule', canActivate: [UserGuard]},
  { path : 'admin', loadChildren: './admin/admin.module#AdminModule', canActivate: [UserGuard]},
  { path : 'user', loadChildren: './user/user.module#UserModule'},
  { path : 'map', loadChildren: './map/map.module#MapModule'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
