import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RRFirebaseService } from '../rrcore/rrfirebase.service';
import { RRLib } from '../rrcore/rrlib.service';

const defaultProfile = '../assets/img/profile.jpg'
let subs
@Injectable({
  providedIn: 'root'
})
export class UserService {

  client: string
  clientConfig: any
  config = new BehaviorSubject<any>(null)
  defaultConfig: any
  isLoggedIn = new BehaviorSubject<boolean>(false)
  profileURL = new BehaviorSubject<string>(defaultProfile)
  userData = new BehaviorSubject<UserData>(null)
  userName: string
  lastPage: string
  lookups: any;
  territory_ids = new BehaviorSubject<string[]>(null)
  territoryInfo = {}

  private schemas = {}


  constructor(private ds: RRFirebaseService) {

    this.ds.getValue('/default/config')
      .then((config) => {
        this.defaultConfig = config
        this.updateConfig()
      })

    if (this.ds.user && this.ds.user.email) {

      this.lastPage = localStorage.getItem('lastpage')
      this.isLoggedIn.next(true)
      this.client = ds.user.email.split('@')[1]

      this.setUser(this.ds.user)

    }
  }

  getConfig(option: string): Promise<any> {
    const config = this.config.getValue()
    if (config) {
      return Promise.resolve(config[option])
    } else {
      return this.ds.getValue(`${this.client}/config/`)
        .then((config) => {
          this.clientConfig = config
          this.updateConfig()
          return config[option]
        })

    }
  }

  getLookups(option?: string): Promise<any> {

    if (this.lookups == null) {
      const p1 = this.ds.getValue('/default/lookups')
      const p2 = this.ds.getValue(`/${this.client}/lookups`)

      return Promise.all([p1, p2]).then((results) => {
        console.log('ALL LOOKUPS', results)
        this.lookups = {}
        results.forEach(result => {
          for (let key in result.value) {
            this.lookups[key] = result.value[key]
          }
        })
        if (option) {
          return Promise.resolve(this.lookups[option])
        }
        return this.lookups
      })
    } else {
      if (option) {
        return Promise.resolve(this.lookups[option])
      }
      return Promise.resolve(this.lookups)
    }
  }

  getLookupsKey(key: string): Promise<any> {
    if (this.lookups && this.lookups[key]) {
      return Promise.resolve(this.lookups[key])
    } else {
      if (!this.lookups) {
        this.lookups = {};
      }

      if (key == 'territory_ids') {
        return this.ds.shallowRead(`${this.client}/territories`)
          .then(ids => {
            const tids = Object.keys(ids).sort(RRLib.sort).join('||')
            this.lookups[key] = tids
            return tids
          })
      }

      return this.ds.getValue(`/${this.client}/lookups/${key}`)
        .then(lookup => {
          if (lookup != null) {
            this.lookups[key] = lookup
            console.log('LOOKUP', lookup)
            return lookup
          }
          else {
            return this.ds.getValue(`/default/lookups/${key}`).then(lookup => {
              this.lookups[key] = lookup
              console.log('DEFAULT LOOKUP', lookup)
              return lookup
            })
          }
        })
    }
  }


  getSchema(viewName: string): Promise<Schema[]> {

    if (this.schemas[viewName]) {
      return Promise.resolve(this.schemas[viewName])
    }

    const setSchema = (r2) => {

      const sch = Object.keys(r2).map(k => {
        const _field = r2[k]
        _field['name'] = k

        return _field

      })
      this.schemas[viewName] = sch
      return sch
    }

    return this.ds.getValue(`${this.client}/config/R2Schema/${viewName}`)
      .then(r2 => {
        if (r2) {
          return setSchema(r2)
        } else {
          return this.ds.getValue(`/default/config/R2Schema/${viewName}`)
            .then(r2 => {
              return setSchema(r2)
            })
        }
      })

  }

  getTerritoryConfig(tid: string): Promise<any> {
    const tinfo = this.territoryInfo[tid]

    if (tinfo) {
      return Promise.resolve(tinfo)
    } else {
      console.log('TIDS getting data', tid)

      return this.ds.getValue(`${this.client}/territories/${tid}/${RRLib.CYCLE}/`)
        .then(ti => {
          console.log('terr data', ti)
          if (ti) {
            this.territoryInfo[tid] = ti
          }
          return ti
        })
    }
  }

  isAllowed(access: string): boolean {
    const ud = this.userData.getValue()
    return ud && ud.access && ud.access.indexOf(access) !== -1
  }

  login(username: string, password: string): Promise<any> {
    return this.ds.login(username, password)
      .then(user => {

        this.setUser(user).then(() => {
          this.isLoggedIn.next(true)
        })

      })
      .catch(err => {
        this.isLoggedIn.next(false)
        throw err
      })
  }

  logout(): Promise<any> {
    return this.ds.logout()
      .then(() => {
        this.userName = null
        this.client = null
        this.userData.next(null)
        this.profileURL.next(defaultProfile)
        this.isLoggedIn.next(false)
      })
  }

  setUser(user: any): Promise<any> {

    let u = user.email.split('@')
    this.client = u[1].split('.')[0];
    this.userName = u[0];

    console.log('USER', user)

    return this.ds.getValue(`${this.client}/users/${this.userName}`)
      .then((d) => {

        if (d) {

          if (d.profile_picture) {
            this.ds.getDownloadURL(`${this.client}/${d.profile_picture}`)
              .then(url => this.profileURL.next(url))
          }

          if (d.access.indexOf('admin') !== -1) {
            this.territory_ids.next([d.territory_id])
          } else {
            this.territory_ids.next(d.territory_id.split('||'))
          }

          this.userData.next(d)
        }
      })
  }

  updateConfig() {
    console.log('Updating Config')
    if (this.defaultConfig && this.clientConfig) {
      var config = {};

      for (let id in this.defaultConfig) {
        config[id] = this.defaultConfig[id];
      }

      for (let id in this.clientConfig) {
        config[id] = this.clientConfig[id];
      }

      // for (let id in this.userConfig) {
      //     config[id] = this.userConfig[id];
      // }

      this.config.next(config);

    }

  }
}

export interface UserData {
  name: string
  territory_id: string
  profile_picture: string
  role: string
  user_type: string
  key: string
  email: string
  access: string
  password: string
  userid: string
  manager: string
}

export class Schema {
  name: string
  label: string
  type: string
  options: string[]
  order: number
  width: string
}