import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatToolbar } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule, MatSidenav } from '@angular/material/sidenav';
import { MatIconModule, MatIcon } from '@angular/material/icon';
import { MatListModule, MatList } from '@angular/material/list';
import { MatCardModule, MatCard} from '@angular/material/card'

import { RRDisplayUserComponent } from './rrdisplay-user/rrdisplay-user.component';
import { SignatureComponent } from './signature/signature.component';
import { MaterialModule } from '../material/material.module';
import { RRDatePipe } from './rrdate.pipe';
import { HttpClientModule } from '@angular/common/http';
import { RRFormEntryComponent } from '../rrcore/rrform-entry/rrform-entry.component';
import { ChipsAutocompleteComponent } from './chips-autocomplete/chips-autocomplete.component';
import { TerritoryPickerComponent } from './territory-picker/territory-picker.component';
import { CyclePickerComponent } from './cycle-picker/cycle-picker.component';

@NgModule({
  declarations: [ RRDisplayUserComponent, SignatureComponent, RRDatePipe, RRFormEntryComponent, ChipsAutocompleteComponent, TerritoryPickerComponent, CyclePickerComponent],
  imports: [
    CommonModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MaterialModule,
    HttpClientModule
  ],
  exports : [
    RRDisplayUserComponent, SignatureComponent, RRDatePipe, RRFormEntryComponent, TerritoryPickerComponent, CyclePickerComponent
  ]
})
export class RRCoreModule { }
